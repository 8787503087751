import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Todos los días utilizamos aplicaciones que nos hacen la vida mucho más fáciles. Si te has preguntado cómo se crean estas magnificas obras de ingeniería te sorprenderá saber, que no fueron creadas de la noche a la mañana.`}</p>
    <p>{`Con mucha seguridad aquellas aplicaciones que utilizas diariamente, fueron meticulosamente elaboradas por muchas horas y con gran destreza, y muy seguramente empezaron como algo tan pequeño como una simple idea.`}</p>
    <h2>{`La Validación`}</h2>
    <p>{`Toda aplicación empieza como una idea, y como todas las ideas, debe ser tratada como una hipótesis. Una hipótesis es una suposición la cual debe ser validada con la recopilación de datos a través de un método de investigación científico.`}</p>
    <p>{`En el mundo de la creación de software la manera de validar una idea es creando una versión cruda y darla gratuitamente a varios usuarios para que la prueben, escuchando sus pensamientos y opiniones al utilizarla. Esta etapa es conocida como las pruebas beta y su finalidad es obtener reacciones e información que no son posibles prever durante la etapa inicial del desarrollo.`}</p>
    <p>{`Las pruebas son hechas en ciclos. Cada ciclo tiene como fin mejorar la calidad del software. Muchas veces, después de varias iteraciones, se termina con una aplicación muy diferente a la que empezó. A esto se le llama el pivoteo.`}</p>
    <h1>{`El Pivoteo`}</h1>
    <p>{`El pivoteo es una de las etapas más comunes en la creación de aplicaciones, a pesar que no todas las aplicaciones pasan por esta etapa.`}</p>
    <p>{`Una de las más famosas historias de pivoteo es la de Instagram. Esta aplicación, utilizada por decenas de millones de personas, comenzó como otra aplicación llamada Burbn, la cual se utilizaba para compartir sitios frecuentados por los usuarios con la capacidad para comentar y compartir fotos, algo así como Foursquare.`}</p>
    <p>{`Los fundadores de la aplicación le hicieron pruebas beta y al recibir halagos por la forma tan fácil de compartir fotos y comentarios, decidieron eliminar todas las otras características y concentrar todos sus esfuerzos en la función de fotografía. Al publicar la aplicación, después de muchas iteraciones, tuvieron miles de descargas en tan sólo minutos, y se convirtió en una de las aplicación de fotos más popular del mundo.`}</p>
    <p>{`La etapa de prueba beta, es tan importante en la creación de software que se le invierte la mayoría de los recursos disponibles. Se crea una versión, se prueba con usuarios, se recopila la información y se cambia lo necesario hasta obtener una versión que esté lista para el mercado.`}</p>
    <p>{`Asi que si estás pensando crear tu propia aplicación, no dudes en empezar. Recuerda que al igual que todos los software que existen, empezaron como una simple idea, y fueron probadas y cambiadas hasta tener algo que sea agradable. Parece un camino largo, pero es el mejor camino para probar tu idea.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      